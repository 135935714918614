import Link from "next/link";
import React from "react";
import { usecases_data } from "../../data/usecases_data";
import Accordion from "../../components/accordion";


const UseCases = () => {

  return (
    <div>
        {/* <!-- Help Center --> */}
        <section className="relative py-14">
          <div className="width-full">
            <Accordion data={usecases_data} />
          </div>
        </section>

    </div>
  );
};

export default UseCases;
