import { useForm } from 'react-hook-form';


const Subscribe = () => {
    const currentApiVersion = "api/3";
    const domainUrl = "campaign/" + currentApiVersion;
    // const domainUrl = "https://envisagesoftware.api-us1.com/" + currentApiVersion;
  
    const apiKey =
      "c34fc7fe8902bf80e6b96e57a5b50bfcdade3409a34a7dd413aa0a802288e5b829170f8a";
    const tags = ["test-cakery"];
  
    //c34fc7fe8902bf80e6b96e57a5b50bfcdade3409a34a7dd413aa0a802288e5b829170f8a
  
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
    });
  
    async function postData(url = "", data = {}) {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Token": apiKey,
        },
        body: JSON.stringify(data),
      });
      return response;
    }
  
    async function onSubmit(data) {
      var url = domainUrl + "/contact/sync";
      let contactData = {
        contact: {
          email: data.email,
          firstName: "",
          lastName: "",
        },
      };
  
      postData(url, contactData)
        .then((response) => response.json())
        .then((data) => {
  
          console.log("Success:", data);
          const contactId = data.contact.id;
          let listAdd = {
            contactList: {
              list: 6,
              contact: contactId,
              status: 1,
            },
          };
  
          postData(domainUrl + "/contactLists", listAdd)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              alert("Thanks! We'll keep you posted with updates.")
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  
      // postData(url, data).then((res) => {
      // alert(res.body);
      // console.log(res, "resBody");
      // const contactId = res.body.contact.id;
      // let listAdd = {
      //   contactList: {
      //     list: 6,
      //     contact: contactId,
      //     status: 1,
      //   },
      // };
      // postData(domainUrl + "/contactLists", listAdd).then((res) => {
      //   console.log(res.body);
      // });
      // });
  
      // POST https://{{yourAccountName}}.api-us1.com/api/{{apiVersion}}/contacts
      // {
      // 	"contact": {
      // 		"email": "johndoe@example.com",
      // 		"firstName": "John",
      // 		"lastName": "Doe",
      // 		"phone": "7223224241"
      //     }
      // }
  
      // POST https://{{yourAccountName}}.api-us1.com/api/{{apiVersion}}/contactLists
      //  Header Api-Token: {}
      //   {
      //     "contactList": {
      //         "list": 1,
      //         "contact": 21,
      //         "status": 1
      //     }
      //   }
    }
  

  return (
          <form className="relative" onSubmit={handleSubmit(onSubmit)}>
            <input
              {...register('email', { required: true })}
              type="email"
              placeholder="Email address"
              className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
            />
            <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
              Subscribe
            </button>
          </form>
  );


}

export default Subscribe;