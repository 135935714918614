const usecases_data = [
	{
		id: '0Hackathon team',
		title: "Hackathon team: You're a team going for that big $$$k prize 🏆",
		text: "You've never worked together. On winning, would you split equally?\n Sounds 'fair', but what if someone slacks off half way through? \n Commiting your contributions to Accord and voting ensures everyone receives their due share. ",
	},
	{
		id: '1ServiceDAO',
		title: 'Service DAO: A pool of talent rewarded for their time',
		text: "You can't deliver that project on your own, a group of you get together to deliver on the requirements. The client pays for the finished product but who did the majority of the work? Accord will tell you how to divide the proceeds.",
	},
	{
		id: '2NFTDAO',
		title: 'NFT Project: Incentivise talent and supporters',
		text: "You've a vision of the finished project but you need great designers, marketers, engineers & more... Accord helps you provide the incentives for the core team and even project supporters as you begin to market in communities and reward them fairly.",
	},

	{
		id: '3GitHub',
		title: 'Ambitious GitHub: A little lib with a commercial future 👩🏽‍💻',
		text: 'Contributing to open-source projects is a great way to grow your skills and create opportunities.\n Imagine if that little project grew into something bigger!  Managing contributions with Accord ensures everyone will be compensated when it pays.',
	},

	{
		id: '4Sub DAO',
		title: 'Sub DAO: The spin off project within an established DAO ↪️',
		text: "You're a DAO master. A few members want to explore a new project within the community. What do you do? You can save a heap of complexity by just spinning up a new Cake and whitelisting the contributors. \n When all is done. You can Bake and reward the contributors in your native token. ",
	},
	{
		id: '5Web3',
		title: 'Web3 Community with a DAO destiny 🛤️',
		text: "Tokenizing is somewhere in your roadmap but you've got a few things to work out together yet.\n Accord is a nocode way to incentivise members to contribute. \n Ultimately, you'll tokenize or reward significant early contributors with NFTs. Regardless, start measuring contributions now.",
	},
	{
		id: '10Any project',
		title: '... any project or startup 📈',
		text: 'You want to stay nimble. Not quite ready to incorporate yet?\n  Startup with Accord and flip into shares or tokens whatever type of entity suits and at the right time.',
	},
];

export { usecases_data };