import React from "react";
import Link from "next/link";
// import Subscribe from "../subscribe";
import DiscordSubscribe from "../discord-subscribe";
import DiscordGated from "../discord-gated";

const Hero_App = () => {
  return (
    <section className="relative pt-20 md:pt-32 h-1527">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <img
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
        />ßß
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
        />
      </picture>

      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-5">
            <div>
            <h1 className="text-jacarta-700 font-display mb-6 font-medium text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">
              Do it now.<br/>DAO it later!
            </h1>
            {/* <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">
              Bake a Bigger&nbsp;Cake! <br/> Build a Longer&nbsp;Table.
            </h1> */}
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              No-code, no-token, pain free dApp.<br/>
              Start tracking contributions for equity &amp; manage governance in your web3 community or startup.  
            </p>
            </div>

            <div className="mx-auto mt-2 w-3/4 text-center">
              <p className="text-jacarta-700 mx-auto mt-5 mb-5 max-w-2xl text-center text-lg dark:text-white">
                Get early access!
              </p>

              <DiscordGated/>

              {/* <Subscribe/> */}
              {/* <form className="relative" onSubmit={(e) => e.preventDefault()}>
                <input
                  type="email"
                  placeholder="Email address"
                  className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
                />
                <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
                  Subscribe
                </button>
              </form> */}
            </div> 


            {/* <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              Equity sharing for DAOs, communities &amp; bootstrapping startup founders.  
            </p> */}

            {/* <div className="flex space-x-4">
              <Link href="/create">
                <a className="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                  Upload
                </a>
              </Link>
              <Link href="/collection/explore_collection">
                <a className="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume w-36 rounded-full bg-white py-3 px-8 text-center font-semibold transition-all hover:text-white">
                  Explore
                </a>
              </Link>
            </div> */}
          </div>

          {/* <!-- Hero image --> */}
          <div className="col-span-6 xl:col-span-7">
            <div className="relative text-center md:text-right">
              <img
                src="/images/hero/app_cake.png"
                alt=""
                className="mt-8 inline-block sm:w-full lg:w-4/5 xl:w-11/12"
              />
              <img
                src="/images/hero/floating_elements.png"
                alt=""
                className="animate-fly absolute top-0 md:-right-[10%]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero_App;
