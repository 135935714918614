import React from 'react';
import HeadLine from '../headLine';
import { featureData } from '../../data/featureData';
import Image from 'next/image';

const AppFeatures = ({ bgWhite = true }) => {
	return (
		<section className="dark:bg-jacarta-800 relative py-24">
			{bgWhite && (
				<picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
					<Image src="/images/gradient_light.jpg" alt="gradient" className="h-full" layout="fill" />
				</picture>
			)}

			<div className="container">
				<HeadLine
					text="Mobile dApp for Founders &amp; Contributors"
					classes="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white"
				/>

				<div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4">

					<div className="text-center newseLatter-item">
						<div
							className={`inline-flex w-feat items-center justify-center rounded-full`}
						>
							<Image
								src={"/images/features/cake-puzzle.png"}
								alt="Cakery blockchain equity slice pie cakes"
								width={280}
								height={271}
							/>
						</div>

						<h3 className="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
							Build Now. No Paper
						</h3>
						<p className="dark:text-jacarta-300">Get everyone started verifying each others contributions.</p>
					</div>

					<div className="text-center newseLatter-item">
						<div
							className={`inline-flex w-feat items-center justify-center rounded-full`}
						>
							<Image
								src={"/images/features/cake-contrib.png"}
								alt="Earn equity in DAOs."
								width={197}
								height={203}
							/>

						</div>

						<h3 className="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
							Track Contributions
						</h3>
						<p className="dark:text-jacarta-300">Your contributions baked on the blockchain as proof of your work.</p>
					</div>

					<div className="text-center newseLatter-item">
						<div
							className={`inline-flex w-feat items-center justify-center rounded-full`}
						>
							<Image
								src={"/images/features/cake-slice.png"}
								alt="Cakery blockchain equity slice pie cakes"
								width={191}
								height={197}
							/>
						</div>

						<h3 className="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
							See Equity Anytime
						</h3>
						<p className="dark:text-jacarta-300">Each members slice & contribution is visible for all to see.</p>
					</div>

					<div className="text-center newseLatter-item">
						<div
							className={`inline-flex w-feat items-center justify-center rounded-full`}
						>
						<Image
								src={"/images/features/cake-baking.png"}
								alt="Founders: Incentivise contribution."
								width={230}
								height={266}
							/>
						</div>

						<h3 className="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
							Bake your DAO!
						</h3> 
						<p className="dark:text-jacarta-300">On founding receive tokens or equity in the new entity.</p>
					</div>

				</div>

				{/* <HeadLine 
					text="Members: Earn equity in DAOs." 
					classes="mt-10 font-display text-jacarta-700 mb-2 text-center text-xl dark:text-white"
				/>
				<HeadLine 
					text="Founders: Incentivise contribution." 
					classes="mt-2 font-display text-jacarta-700 mb-16 text-center text-xl dark:text-white"
				/> */}

			</div>
		</section>
	);
};

export default AppFeatures;
