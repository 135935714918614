import React from 'react';
import {
	Hero_App,
} from '../../components/component';
import AppFeatures from '../../components/landing/app-features';
import Features from '../../components/landing/features';
import Meta from '../../components/Meta';
import UseCases from '../../components/landing/usecases';

import Script from 'next/script'
import Head from 'next/head';
import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';

const Landing = () => {

	useEffect(() => {

		const hjid = 3241029;
		const hjsv = 6;
	
		hotjar.initialize(hjid, hjsv);
	
		if (hotjar.initialized()) {
			hotjar.identify('USER_ID', { userProperty: 'value' });
		}
	
		// Add an event
		hotjar.event('loaded cakery');

	})


	return (
		<>
		<Head>
			{/* <!-- Hotjar Tracking Code for https://cakery.io --> */}
			{/* <Script id="hotjar" strategy="beforeInteractive" dangerouslySetInnerHTML= {{
			_html: `
				(function(h,o,t,j,a,r){
						h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
						h._hjSettings={hjid:3241029,hjsv:6};
						a=o.getElementsByTagName('head')[0];
						r=o.createElement('script');r.async=1;
						r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
						a.appendChild(r);
				})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
			}}
			/> */}
		</Head>
		<main>
			<Meta />
			<Hero_App />
			{/* <Features /> */}
			<AppFeatures/>	
			<UseCases/>

			{/* <NextScript /> */}
			<Script async src="https://www.googletagmanager.com/gtag/js?id=G-XJTGHLMQYL"></Script>
			<Script id="google-analytics" strategy="afterInteractive" dangerouslySetInnerHTML= {{
				_html: `window.dataLayer = window.dataLayer || [];
				function gtag(){window.dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', 'G-G-XJTGHLMQYL);`

			}} />


		</main>
		</>
	);
};

export default Landing;
